import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';

export const isMaxDialog = ref(false);

export const isNestedDialogOpen = ref(false);
export function setNestedDialogOpen(isOpen: boolean) {
  isNestedDialogOpen.value = isOpen;
}

export const autoScroll = reactive({ selector: '', itemIndex: -1 });
export const autoScrollTo = (selector: string, itemIndex: number | -1) => {
  const el = document.querySelector(itemIndex > 0 ? `${selector}_${itemIndex}` : selector);
  if (el) {
    autoScroll.selector = selector;
    autoScroll.itemIndex = itemIndex ?? -1;
    itemIndex !== -1
      ? el.scrollIntoView({ block: 'center', behavior: 'smooth' })
      : el.scrollIntoView({ behavior: 'smooth' });
  }
};
export const unsetAutoScroll = () => {
  autoScroll.selector = '';
  autoScroll.itemIndex = -1;
};

export const fixOverlayPosition = (selector, top?) => {
  const el = document.querySelector(selector);
  if (el) {
    el.classList.add('fixed');
    if (top) {
      el.style.top = `${top}px`;
    }
  }
};

export const getTimeout = (time: number | string | null) => {
  if (!time) {
    return 0;
  }
  const timeout = +time - Math.floor(Date.now());
  return timeout > 0 ? timeout : 0;
};

export const addressForPrint = (
  address: string | null | undefined,
  slicePosition?: [],
): string | null => {
  const { state } = useStore();
  const positions = slicePosition ?? [8, 4, 6];
  const result = null;
  if (address) {
    if (state.layout.isDesktop) {
      return address.slice(0, positions[0]) + '...' + address.slice(-4);
    } else {
      return state.layout.isTablet
        ? address.slice(0, positions[1]) + '...' + address.slice(-4)
        : address.slice(0, positions[2]) + '...' + address.slice(-4);
    }
  }
  return result;
};

export const scrollToElement = (el: Element | null) => {
  if (el) {
    const top = el.getBoundingClientRect().top + window.pageYOffset - 80;
    window.scrollTo({ top, behavior: 'smooth' });
  }
};
export function useUtils() {
  function openExternalLink(url) {
    window.open(url, '_blank');
  }
  function textEllipsis(text: string, left: number, right?: number) {
    let result = '';
    if (right) {
      result = text.length > left + right ? text.slice(0, left) + '...' + text.slice(-right) : text;
    } else {
      result = text.length > left ? text.slice(0, left) + '...' : text;
    }
    return result;
  }
  function setBodyOverflow(isHidden: boolean) {
    isHidden
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden', 'p-overflow-hidden');
  }

  function setMaxDialog(isOpen: boolean) {
    isMaxDialog.value = isOpen;
    setBodyOverflow(isOpen);
  }
  return {
    openExternalLink,
    setBodyOverflow,
    textEllipsis,
    setMaxDialog,
  };
}

export const getUniqueId = () => {
  const msTime = performance.now().toString(36);
  return (msTime + Math.random().toString(36).substr(2)).replace('.', '');
};
